* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f1f5f8;
    color: #222;
}

.booklist {
    width: 90vw;
    max-width: 1170px;
    margin: 5rem auto;
    display: grid;
    gap: 2rem;
    align-items: start;
}

@media screen and (min-width: 768px) {
    .booklist {
        grid-template-columns: repeat(3, 1fr);
    }
}

.booklist img {
    width: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
}

.book {
    background-color: #fff;
    border-radius: 1rem;
    padding: 2rem;
    position: relative;
}

.book h2 {
    margin-bottom: 1rem;
}

nav.main-nav {
    margin: 1rem;
}

nav.main-nav a {
    padding: 5px 15px;
    margin-right: 10px;
    border-radius: 0.75rem;
    transition: 0.2s;
    text-decoration: none;
    color: teal;
}

nav.main-nav a:hover {
    background-color: teal;
    color: #fff;
}

.on-sale {
    margin-top: 1rem;
    font-weight: bold;
    transform: rotate(-10deg);
    color: red;
    background-color: yellow;
    padding: 2px 5px;
}

.rank {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1rem;
    background-color: #c35600;
    color: #fff;
    padding: 0.75rem 0.25rem;
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

h1.page-title {
    text-align: center;
    margin-top: 4rem;
    text-transform: capitalize;
}